import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';

const EspetaculosNarrativosPage = ({data, ...props}) => {
  const pageTitle = "Espetáculos Narrativos";
  const keywords = "espetáculo, espetáculo narrativo, Leituras no Litoral";
  const description = "Espetáculos Narrativos do projeto Leituras no Litoral";

  const hasEvents = false;

  const events = () => {
    return (
      <div>
        {
          categoryDescription()
        }

        <div className='row u-margin-top'>
          {
            data.allMdx.nodes.map((node) => {
              const image = getImage(node.frontmatter.hero_image);
              const imageAlt = node.frontmatter.hero_image_alt;
              const to = `/espetaculos-narrativos/${node.slug}`;
              const title = node.frontmatter.title;
              const date = node.frontmatter.date;
              const time = node.frontmatter.time;
              const location = node.frontmatter.location;
              const classification = node.frontmatter.classification;

              return (
                <div className='col-xs-12 col-sm-6' key={node.id}>
                  <article className='event-card'>
                    <Link
                      to={to}
                      title={title}
                    >
                      <div className='event-card__img'>
                        <GatsbyImage
                          image={image}
                          alt={imageAlt}
                          title={imageAlt}
                        />
                      </div>

                      <div className='event-card__body'>
                        <h2>
                          {title}
                        </h2>

                        <ul className='u-reset-list'>
                          <li><strong>Data:</strong> {date}</li>            
                          <li><strong>Horário:</strong> {time}</li>            
                          <li><strong>Local:</strong> {location}</li>
                          <li><strong>Classificação indicativa:</strong> {classification}</li>
                        </ul>

                      </div>
                    </Link>
                  </article>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  };

  const categoryDescription = () => {
    return (
      <div className='row'>
        <div className='col-xs-12'>
          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Sobre</h2>

              <p>O <strong>Grupo Baquetá</strong> (Curitiba/PR) apresentará o espetáculo <strong>Karingana Ua Karingana! Histórias de Áfricas</strong> nas praças das cidades de Matinhos e Pontal do Paraná para o público de todas as idades.</p>

              <p>Contar uma história é encontrar as sementes e puxar da raiz a nossa própria história. Estas são chamadas de karinganas em Moçambique, um dos 55 países do continente africano, e <strong>divertem e ensinam adultos e crianças</strong>. Para que a história comece, o contador grita: “Karingana ua Karingana?” E quem ouve, responde: “Karingana!”. As karinganas, contadas em roda, são ricas em ritmos, danças, cores e ancestralidade. De onde vem nossas raízes?</p>
            </div>
          </div>

          <div className="wave wave--left wave--2"></div>

          <div className='content-card u-margin-top'>
            <div className='content-card__body'>
              <h2>Grupo Baquetá</h2>

              <p>Grupo Baquetá nasceu em 2009 na cidade de Curitiba/PR e propõe-se a pesquisar e desenvolver espetáculos e oficinas com foco nos saberes da diáspora africana e dos povos indígenas do Brasil, para adultos e crianças nas áreas de teatro, música, dança, literatura e artes visuais. Tem como compromisso construir coletivamente, em todos os locais que atua, contextos que instiguem reflexões e entendimentos que promovam o respeito às diversidades através de práticas artísticas.</p>

              <ul>
                <li><strong>Texto:</strong> Kamylla dos Santos</li>
                <li><strong>Composições:</strong> Kamylla dos Santos e André Daniel</li>
                <li><strong>Elenco:</strong> André Daniel, Kamylla dos Santos e Maycon Souza</li>
                <li><strong>Trilha sonora:</strong> Maycon Souza</li>
                <li><strong>Iluminação:</strong> Bianca Lima</li>
                <li><strong>Produção:</strong> Kamylla dos Santos</li>
                <li><strong>Assistente de Produção:</strong> Maikon Silva</li>
                <li><strong>Figurinos:</strong> Leonilda Santos e Naiarte</li>
                <li><strong>Adereços:</strong> Olho Místico</li>
                <li><strong>Cenário:</strong> Maikon Silva e Naiarte</li>
              </ul>

              <p className='u-margin-top'><a href="http://www.grupobaqueta.com.br" target='_blank' rel="noreferrer" title='Ir para o site do Grupo Baquetá'>www.grupobaqueta.com.br</a></p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout
      pageTitle={pageTitle}
      keywords={keywords}
      description={description}
      {...props}
    >
      {
        hasEvents ? events() : categoryDescription()
      }
    </Layout>
  )
};

// export const query = graphql`
//   query {
//     allMdx(
//       sort: {fields: frontmatter___date, order: ASC}
//       filter: {fileAbsolutePath: {regex: "/(espetaculos-narrativos)/"}}
//     ) {
//       nodes {
//         frontmatter {
//           title
//           time
//           location
//           classification
//           date(formatString: "DD MMMM, YYYY", locale: "PT-BR")
//           hero_image {
//             childImageSharp {
//               gatsbyImageData(
//                 width: 700
//                 aspectRatio: 1.33
//                 transformOptions: {
//                   fit: COVER
//                   cropFocus: ATTENTION
//                 }
//               )
//             }
//           }
//           hero_image_alt
//         }
//         id
//         slug
//       }
//     }
//   }
// `;

export default EspetaculosNarrativosPage;